@media screen and (max-width: 1000px) {
	.members-list {
		display: none;
	}
}

.list-item-button:hover {
	background: white !important;
}

.list-item-custom {
	margin: -1em;
}

.avatar img {
	object-fit: contain;
}
