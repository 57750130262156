a:active {
  background-color: #fff;
}
/* .page {
  margin-top: 2%;
  margin-left: 45%;
} */
.pageStyle {
  cursor: pointer;
  margin: 0px 3px;
  padding: 3px 8px;
  color: #42b76d;
  font-size: 110%;
  border-radius: 50%;
}
.pageStyle:hover{
    color: #42b76d;
    border: 1px solid #42b76d;
}
.bgc {
  color: #fff;
  background-color: #42b76d;
}
.bgc:hover{
    color: #fff;
    border: none;
}
@media screen and (max-width: 768px) {
  /* .page {
    margin-left: 40%;
  } */
}
