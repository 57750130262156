.welcome {
	padding: 30px;
	border: 1px solid;
	box-shadow: 5px 5px #e9e9e9;
	background: #fff;
	display: flex;
	width: 70%;
	border-radius: 5px;
}

.description {
	padding: 5px;
}

.custom-card {
	width: 100%;
	margin-left: "30px";
	margin-top: 30px;
}

.link {
	text-decoration: none;
	color: #000;
}

.custom-textfield {
	border: #c1c1c1;
}

.editor {
	margin-top: 1em;
}

.custom-editor {
	border-radius: 5px;
}

.custom-editor:hover {
	border: 1px solid black;
}

.post-section {
	position: relative;
}

.post-button {
	width: 6em;
	height: 1.5em;
}

.card-action {
	background-color: #42b7a7;
	margin: 1em;
	border-radius: 5px;
}

.card-title {
	font-size: 21px;
}

@media screen and (max-width: 1000px) {
	.custom-img {
		display: none;
	}
	.custom-card {
		width: 100%;
		margin-left: "20px";
		margin-top: 20px;
	}
	.post-section {
		position: relative;
	}

	.card-title {
		font-size: 17px;
	}
}

.create-post-form {
	display: flex;
	justify-content: space-between;
}

.editor {
	border-radius: 5px;
}

.editor:hover {
	border: 1px solid black;
}

.hello {
	border-radius: 100px;
	border: 5px solid black;
}
