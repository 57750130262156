.post-card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 900px;
  background-color: white;
  border-bottom: 2px solid #ebebeb;
  min-height: 40px;
  padding: 0.5rem 2rem;
  margin-bottom: 0.5rem;
}
.post-card-container a {
  color: black;
}
.post-card-container i {
  color: #666;
  padding: 0 1rem;
}
.post-card-container i:hover {
  color: black;
}
