@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

:root {
	--header-height: 3rem;
	--nav-width: 68px;
	--first-color: #fff;
	--first-color-light: #000;
	--white-color: #42b7a7;
	--body-font: "Roboto", sans-serif;
	--normal-font-size: 1rem;
	--z-fixed: 100;
}

*,
::before,
::after {
	box-sizing: border-box;
}

body {
	position: relative;
	margin: var(--header-height) 0 0 0;
	background: #f5f5f5;
	padding: 0 1rem;
	font-family: var(--body-font);
	font-size: var(--normal-font-size);
	transition: 0.5s;
}

a {
	text-decoration: none;
}

.header {
	width: 100%;
	height: var(--header-height);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
	background-color: #fff;
	z-index: var(--z-fixed);
	transition: 0.5s;
}

.header_toggle {
	color: var(--first-color);
	font-size: 1.5rem;
	cursor: pointer;
}

.header_img {
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: center;
	border-radius: 50%;
	overflow: hidden;
}

.header_login {
	display: none;
	padding: 3px 6px;
	border-radius: 10%;
	background-color: #42b7a7;
	cursor: pointer;
}

.header_img img {
	width: 40px;
}

.l-navbar {
	position: fixed;
	top: 0;
	left: -30%;
	width: var(--nav-width);
	height: 100vh;
	background-color: var(--first-color);
	padding: 0.5rem 1rem 0 0;
	transition: 0.5s;
	z-index: var(--z-fixed);
}

.nav {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
}

.nav_logo,
.nav_link {
	display: grid;
	grid-template-columns: max-content max-content;
	align-items: center;
	column-gap: 1rem;
	padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
	margin-bottom: 2rem;
}

.nav_logo-icon {
	font-size: 1.25rem;
	color: var(--white-color);
}

.nav_logo-name {
	color: var(--white-color);
	font-weight: 700;
}

.nav_link {
	position: relative;
	color: var(--first-color-light);
	margin-bottom: 1.5rem;
	transition: 0.3s;
}

.nav_link:hover {
	color: var(--white-color);
}

.nav_icon {
	font-size: 1.25rem;
}

.show {
	left: 0;
}

.body-pd {
	padding-left: calc(var(--nav-width) + 1rem);
}

.active {
	color: var(--white-color);
}

.active::before {
	content: "";
	position: absolute;
	left: 0;
	width: 2px;
	height: 32px;
	background-color: var(--white-color);
}

.height-100 {
	height: 100vh;
}

@media screen and (min-width: 768px) {
	body {
		margin: calc(var(--header-height) + 1rem) 0 0 0;
		padding-left: calc(var(--nav-width) + 2rem);
	}

	.header {
		height: calc(var(--header-height) + 1rem);
		padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
	}

	.header_img {
		width: 40px;
		height: 40px;
	}

	.header_img img {
		width: 45px;
	}

	.l-navbar {
		left: 0;
		padding: 1rem 1rem 0 0;
	}

	.show {
		width: calc(var(--nav-width) + 156px);
	}

	.body-pd {
		padding-left: calc(var(--nav-width) + 188px);
	}
}

@media screen and (max-width: 1000px) {
	.header_login {
		display: inline-block;
		margin-left: 60%;
	}
	.listText {
		margin-left: -30px;
		margin-right: 20px;
	}
}

@media screen and (min-width: 1000px) {
	.user-profile {
		display: none;
	}
}

.welcome {
	border: 1px solid;
	box-shadow: 5px 5px #e9e9e9;
	margin: 10px;
	display: flex;
	width: 95%;
	padding: 5px;
	border-radius: 5px;
}

.user-details-navbar {
	background-color: white;
	border-radius: 30px;
	border: none;
	color: black;
}

.description {
	padding: 5px;
}

.link {
	text-decoration: none;
}
