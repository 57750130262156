table thead {
	font-weight: 500;
}
table td,
table th {
	min-width: 100px;
	max-width: 300px;
	padding-right: 1rem;
}
table td img,
table th img {
	border-radius: 50%;
	height: 25px;
	width: 25px;
	-o-object-fit: cover;
	object-fit: cover;
}
table .avatar_p {
	font-size: 15px;
	border-radius: 50%;
	height: 25px;
	width: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 500;
	background-color: #000;
	color: aliceblue;
}
table tr {
	height: 30px;
}
table tr td {
	height: 30px;
}
table .avatar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
table .avatar img,
table .avatar p {
	margin-right: 1rem;
}
.dbug {
	border: 2px dashed #00a743;
}
.dbug .dbug {
	border: 2px dashed tomato;
}
.dbug .dbug .dbug {
	border: 2px dashed #006aff;
}
.navigation-btn {
	margin-top: 1rem;
	display: inline-block;
}
.navigation-btn p {
	background-color: black;
	color: white;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border-radius: 20px;
	font-weight: 500;
	cursor: pointer;
	padding: 0.5rem 2rem;
}
