* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

@media screen and (max-width: 1000px) {
  .custom-btn {
    display: none;
  }
}
i {
  cursor: pointer;
}
