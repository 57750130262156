.outerDiv{
    background-color: #fff;
    margin: auto;
    width: 70%;
}

@media screen and (max-width: 768px) {
    .outerDiv{
        background-color: #fff;
        margin: auto;
        width: 90%;
    }
  }