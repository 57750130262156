.profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}



.profile .img {
  margin: 2rem 0;
  height: 150px;
  width: 150px;
  border: 2px solid black;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.profile .img img {
  height: 150px;
  width: 150px;
  -o-object-fit: cover;
  object-fit: contain;
}

.profile h5 {
  font-size: 2rem;
}


.profile h2 {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #535353;
}

.sub-heading {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: rgb(80, 80, 80);
  margin: 1rem;
}
