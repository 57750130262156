.text {
  word-wrap: break-word;
}

.card-title {
  font-size: 21px;
}

@media screen and (max-width: 768px) {
  .card-title {
    font-size: 17px;
  }
  .card-text {
    padding-top: 10px;
  }
}
