.upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  form > * {
    margin: 20px 0;
    box-sizing: border-box;
  }

  .sample-page{
      width: auto;
  }

  @media screen and (max-width: 1000px) {
    .sample-page{
        width: 250px;
      margin: auto;
    }
  }