.user-details {
	width: 94%;
	margin-top: 40px;
	margin-left: 8px;
	background-color: white;
	border-radius: 30px;
	border: none;
	color: black;
}

.avatar img {
	object-fit: contain;
}

.listItem {
	padding: 0 !important;
}

.listAvatar {
	transform: scale(1.5);
}

.listText {
	margin: 0 2.5em;
}

.listItem:hover {
	background: white !important;
}

@media screen and (max-width: 1100px) and (min-width: 1000px) {
	.listText {
		margin: 0 1.5em;
	}
}

@media screen and (max-width: 1000px) {
	.listText {
		margin: 0 1.5em;
	}
}

@media screen and (max-width: 700px) {
	.listText {
		margin: 0 1em;
	}
}

@media screen and (max-width: 500px) {
	.listText {
		margin: 0 0.5em;
	}
}

@media screen and (max-width: 300px) {
	.listText {
		margin: 0;
	}
}
