*:focus {
  outline: 0 !important;
}

.welcome {
  padding: 30px;
  border: 1px solid;
  box-shadow: 5px 5px #e9e9e9;
  background: #fff;
  display: flex;
  width: 70%;
  border-radius: 5px;
}

.description {
  padding: 5px;
}

.card-text {
  font-size: 18px;
  padding-top: 30px;
}

.custom-card {
  width: 100%;
  margin-left: '30px';
  margin-top: 30px;
}

.card-title {
  font-size: '21px';
}

.link {
  text-decoration: none;
  color: #000;
}

@media screen and (max-width: 1000px) {
  .custom-img {
    display: none;
  }
  .custom-card {
    width: 100%;
    margin-left: '20px';
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .card-title {
    font-size: 17px;
  }
  .card-text {
    padding-top: 10px;
  }
}
