.popup-container {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(221, 221, 221, 0.671);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.popup-container .popup-body {
  background-color: whitesmoke;
  width: 100%;
  max-width: 500px;
  min-height: 200px;
  position: absolute;
  margin: 1rem;
}
.popup-container .popup-body .children {
  padding: 1rem 1.15rem;
}
.popup-container .popup-body .children p {
  font-size: 14px;
  width: 95%;
}
.popup-container .popup-body .children div label {
  font-size: 12px;
  padding-bottom: 5px;
}
.popup-container .popup-body .children div input {
  background: #ffffff;
  border: 0.5px solid #000000;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 14px;
}
.popup-container .popup-body .children div button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #42b7a7;
  color: white;
  border: none;
  width: 100%;
  font-size: 14px;
}
.popup-container .popup-body .close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
}
.popup-container .popup-body .close-popup i {
  padding: 5px;
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  color: gray;
}
.popup-container .popup-body .close-popup i:hover {
  background-color: #e4e4e4;
}
