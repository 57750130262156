.new-user-profile {
  justify-content: space-around;
  align-items: center;
  background-color: white;
  margin-top: 2rem;
}
.new-user-profile .cover-profile {
}
.new-user-profile .cover-profile .bg {
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #6bb9c5 100%);
  width: 100%;
  height: 200px;
  display: block;
}

.new-user-profile .cover-profile .img {
  background-color: #ffffff;
  height: 200px;
  width: 200px;
  padding: 1rem;
  border-radius: 500px;

  margin-top: -4rem;
  margin-left: 5rem;
  margin-right: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.new-user-profile .cover-profile img {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.new-user-profile .cover-profile .user-cover-profile {
  display: flex;
  width: 100%;
  align-items: center;
}
.new-user-profile .cover-profile .user-cover-profile h2 {
  padding-top: 0.5rem;
  font-size: 2rem;
  margin: 0;
}
.new-user-profile .cover-profile .user-cover-profile button {
  font-size: 11px;
  letter-spacing: 0.5px;
  background-color: #237c7e;
  color: white;
  margin: 5px 5px 5px 0;
  border-radius: 50px;
  padding: 0.35rem 1rem;
  font-weight: 500;
  border: none;
}
.new-user-profile .cover-profile .user-cover-profile h2 span i {
  padding-bottom: 0.5rem;
  font-size: 1rem;
  margin: 0;
  color: rgba(128, 128, 128, 0.558);
}
.new-user-profile .cover-profile .user-cover-profile h2 span {
  padding-bottom: 0.5rem;
  font-size: 1rem;
  margin: 0;
  color: rgba(128, 128, 128, 0.822);
  cursor: pointer;
}
.new-user-profile .cover-profile .user-cover-profile h5 {
  font-size: 1rem;
  font-weight: 300;
  margin: 0.5rem 0;
}
.new-user-profile .cover-profile .user-cover-profile p {
  font-size: 14px;
  max-width: 600px;
  margin: 0;
}

.new-user-profile .user-action {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 500px; */
  padding: 5rem 0 2rem;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.new-user-profile .user-action button {
  font-size: 11px;
  letter-spacing: 0.5px;
  background-color: #237c7e;
  color: white;
  margin: 5px;
  border-radius: 50px;
  padding: 0.35rem 1rem;
  font-weight: 500;
}

.section-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  /* max-width: 400px; */
  margin-bottom: 5rem;
}

.new-post-container {
  /* background-color: rgba(255, 255, 255, 0.2); */
  padding: 0 0 2rem;
  margin-bottom: 3rem;
}

.Update-Profile {
  background-color: white;
  padding: 2rem 4rem;
  margin-bottom: 5rem;
}

@media screen and (max-width: 480px) {
  .new-user-profile {
    flex-direction: column;
  }
  .new-user-profile .cover-profile .img {
    margin-top: -7rem;
    margin-left: auto;
    margin-right: auto;
  }
  .new-user-profile .cover-profile .user-cover-profile {
    text-align: center;
  }
  .new-user-profile .cover-profile .user-cover-profile {
    flex-direction: column;
    justify-content: center;
  }

  .Update-Profile {
    padding: 2rem 2rem;
  }
}

.game-rule {
  width: 100%;
  overflow: hidden;
  text-align: center;
  background-color: white;
  margin-bottom: 2rem;
}
.game-rule p {
  font-size: 14px;
}
.game-rule h2 {
  font-size: 1.25rem;
  text-align: center;
  margin: 2rem;
}
.game-rule .avatar img {
  height: 100px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.game-rule .avatar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.game-rule .avatar div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem;
}
.game-rule .avatar p {
  font-size: 13px;
  text-align: center;
}

.point-reward {
  display: flex;
  justify-content: space-around;
}

.level_indicator {
  background-color: rgb(231, 231, 231);
  width: 90%;
  margin: auto;
  height: 10px;
  position: relative;
  border-radius: 10px;
}
.level_indicator span {
  position: absolute;
  height: 10px;
  background-color: rgb(204, 204, 204);
  top: 0;
  left: 0;
  border-radius: 10px;
}

@media screen and (max-width: 480px) {
  .game-rule .avatar div {
    min-width: 125px;
    width: 125px;
    margin: 1rem;
  }

  .point-reward {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .level_indicator {
    display: none;
  }
}
